/* eslint-disable */

/**
 * Modernizr
 * --------------------
 * *You can be based on the added options to check which function the browser can support,
 * *and handle it by another solution.
 *
 * Default added
 * [CSS Object Fit, ES6 Arrow Functions, ES6 Promises]
 *
 * You can make your own settings here: https://modernizr.com/download?setclasses
 * Just copy the js and paste to the below space
 *
 */

// *paste here

/*! modernizr 3.6.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-arrow-objectfit-promises-setclasses !*/
!(function(window, document, undefined) {
  function is(e, n) {
    return typeof e === n;
  }
  function testRunner() {
    var e, n, t, r, o, i, s;
    for (var l in tests)
      if (tests.hasOwnProperty(l)) {
        if (
          ((e = []),
          (n = tests[l]),
          n.name &&
            (e.push(n.name.toLowerCase()),
            n.options && n.options.aliases && n.options.aliases.length))
        )
          for (t = 0; t < n.options.aliases.length; t++)
            e.push(n.options.aliases[t].toLowerCase());
        for (r = is(n.fn, "function") ? n.fn() : n.fn, o = 0; o < e.length; o++)
          (i = e[o]),
            (s = i.split(".")),
            1 === s.length
              ? (Modernizr[s[0]] = r)
              : (!Modernizr[s[0]] ||
                  Modernizr[s[0]] instanceof Boolean ||
                  (Modernizr[s[0]] = new Boolean(Modernizr[s[0]])),
                (Modernizr[s[0]][s[1]] = r)),
            classes.push((r ? "" : "no-") + s.join("-"));
      }
  }
  function setClasses(e) {
    var n = docElement.className,
      t = Modernizr._config.classPrefix || "";
    if ((isSVG && (n = n.baseVal), Modernizr._config.enableJSClass)) {
      var r = new RegExp("(^|\\s)" + t + "no-js(\\s|$)");
      n = n.replace(r, "$1" + t + "js$2");
    }
    Modernizr._config.enableClasses &&
      ((n += " " + t + e.join(" " + t)),
      isSVG ? (docElement.className.baseVal = n) : (docElement.className = n));
  }
  function cssToDOM(e) {
    return e
      .replace(/([a-z])-([a-z])/g, function(e, n, t) {
        return n + t.toUpperCase();
      })
      .replace(/^-/, "");
  }
  function createElement() {
    return "function" != typeof document.createElement
      ? document.createElement(arguments[0])
      : isSVG
      ? document.createElementNS.call(
          document,
          "http://www.w3.org/2000/svg",
          arguments[0]
        )
      : document.createElement.apply(document, arguments);
  }
  function contains(e, n) {
    return !!~("" + e).indexOf(n);
  }
  function domToCSS(e) {
    return e
      .replace(/([A-Z])/g, function(e, n) {
        return "-" + n.toLowerCase();
      })
      .replace(/^ms-/, "-ms-");
  }
  function getBody() {
    var e = document.body;
    return e || ((e = createElement(isSVG ? "svg" : "body")), (e.fake = !0)), e;
  }
  function injectElementWithStyles(e, n, t, r) {
    var o,
      i,
      s,
      l,
      d = "modernizr",
      a = createElement("div"),
      u = getBody();
    if (parseInt(t, 10))
      for (; t--; )
        (s = createElement("div")),
          (s.id = r ? r[t] : d + (t + 1)),
          a.appendChild(s);
    return (
      (o = createElement("style")),
      (o.type = "text/css"),
      (o.id = "s" + d),
      (u.fake ? u : a).appendChild(o),
      u.appendChild(a),
      o.styleSheet
        ? (o.styleSheet.cssText = e)
        : o.appendChild(document.createTextNode(e)),
      (a.id = d),
      u.fake &&
        ((u.style.background = ""),
        (u.style.overflow = "hidden"),
        (l = docElement.style.overflow),
        (docElement.style.overflow = "hidden"),
        docElement.appendChild(u)),
      (i = n(a, e)),
      u.fake
        ? (u.parentNode.removeChild(u),
          (docElement.style.overflow = l),
          docElement.offsetHeight)
        : a.parentNode.removeChild(a),
      !!i
    );
  }
  function fnBind(e, n) {
    return function() {
      return e.apply(n, arguments);
    };
  }
  function testDOMProps(e, n, t) {
    var r;
    for (var o in e)
      if (e[o] in n)
        return t === !1
          ? e[o]
          : ((r = n[e[o]]), is(r, "function") ? fnBind(r, t || n) : r);
    return !1;
  }
  function computedStyle(e, n, t) {
    var r;
    if ("getComputedStyle" in window) {
      r = getComputedStyle.call(window, e, n);
      var o = window.console;
      if (null !== r) t && (r = r.getPropertyValue(t));
      else if (o) {
        var i = o.error ? "error" : "log";
        o[i].call(
          o,
          "getComputedStyle returning null, its possible modernizr test results are inaccurate"
        );
      }
    } else r = !n && e.currentStyle && e.currentStyle[t];
    return r;
  }
  function nativeTestProps(e, n) {
    var t = e.length;
    if ("CSS" in window && "supports" in window.CSS) {
      for (; t--; ) if (window.CSS.supports(domToCSS(e[t]), n)) return !0;
      return !1;
    }
    if ("CSSSupportsRule" in window) {
      for (var r = []; t--; ) r.push("(" + domToCSS(e[t]) + ":" + n + ")");
      return (
        (r = r.join(" or ")),
        injectElementWithStyles(
          "@supports (" + r + ") { #modernizr { position: absolute; } }",
          function(e) {
            return "absolute" == computedStyle(e, null, "position");
          }
        )
      );
    }
    return undefined;
  }
  function testProps(e, n, t, r) {
    function o() {
      s && (delete mStyle.style, delete mStyle.modElem);
    }
    if (((r = is(r, "undefined") ? !1 : r), !is(t, "undefined"))) {
      var i = nativeTestProps(e, t);
      if (!is(i, "undefined")) return i;
    }
    for (
      var s, l, d, a, u, f = ["modernizr", "tspan", "samp"];
      !mStyle.style && f.length;

    )
      (s = !0),
        (mStyle.modElem = createElement(f.shift())),
        (mStyle.style = mStyle.modElem.style);
    for (d = e.length, l = 0; d > l; l++)
      if (
        ((a = e[l]),
        (u = mStyle.style[a]),
        contains(a, "-") && (a = cssToDOM(a)),
        mStyle.style[a] !== undefined)
      ) {
        if (r || is(t, "undefined")) return o(), "pfx" == n ? a : !0;
        try {
          mStyle.style[a] = t;
        } catch (c) {}
        if (mStyle.style[a] != u) return o(), "pfx" == n ? a : !0;
      }
    return o(), !1;
  }
  function testPropsAll(e, n, t, r, o) {
    var i = e.charAt(0).toUpperCase() + e.slice(1),
      s = (e + " " + cssomPrefixes.join(i + " ") + i).split(" ");
    return is(n, "string") || is(n, "undefined")
      ? testProps(s, n, r, o)
      : ((s = (e + " " + domPrefixes.join(i + " ") + i).split(" ")),
        testDOMProps(s, n, t));
  }
  var classes = [],
    tests = [],
    ModernizrProto = {
      _version: "3.6.0",
      _config: {
        classPrefix: "",
        enableClasses: !0,
        enableJSClass: !0,
        usePrefixes: !0
      },
      _q: [],
      on: function(e, n) {
        var t = this;
        setTimeout(function() {
          n(t[e]);
        }, 0);
      },
      addTest: function(e, n, t) {
        tests.push({ name: e, fn: n, options: t });
      },
      addAsyncTest: function(e) {
        tests.push({ name: null, fn: e });
      }
    },
    Modernizr = function() {};
  (Modernizr.prototype = ModernizrProto),
    (Modernizr = new Modernizr()),
    Modernizr.addTest("arrow", function() {
      try {
        eval("()=>{}");
      } catch (e) {
        return !1;
      }
      return !0;
    }),
    Modernizr.addTest("promises", function() {
      return (
        "Promise" in window &&
        "resolve" in window.Promise &&
        "reject" in window.Promise &&
        "all" in window.Promise &&
        "race" in window.Promise &&
        (function() {
          var e;
          return (
            new window.Promise(function(n) {
              e = n;
            }),
            "function" == typeof e
          );
        })()
      );
    });
  var docElement = document.documentElement,
    isSVG = "svg" === docElement.nodeName.toLowerCase(),
    omPrefixes = "Moz O ms Webkit",
    cssomPrefixes = ModernizrProto._config.usePrefixes
      ? omPrefixes.split(" ")
      : [];
  ModernizrProto._cssomPrefixes = cssomPrefixes;
  var atRule = function(e) {
    var n,
      t = prefixes.length,
      r = window.CSSRule;
    if ("undefined" == typeof r) return undefined;
    if (!e) return !1;
    if (
      ((e = e.replace(/^@/, "")),
      (n = e.replace(/-/g, "_").toUpperCase() + "_RULE"),
      n in r)
    )
      return "@" + e;
    for (var o = 0; t > o; o++) {
      var i = prefixes[o],
        s = i.toUpperCase() + "_" + n;
      if (s in r) return "@-" + i.toLowerCase() + "-" + e;
    }
    return !1;
  };
  ModernizrProto.atRule = atRule;
  var domPrefixes = ModernizrProto._config.usePrefixes
    ? omPrefixes.toLowerCase().split(" ")
    : [];
  ModernizrProto._domPrefixes = domPrefixes;
  var modElem = { elem: createElement("modernizr") };
  Modernizr._q.push(function() {
    delete modElem.elem;
  });
  var mStyle = { style: modElem.elem.style };
  Modernizr._q.unshift(function() {
    delete mStyle.style;
  }),
    (ModernizrProto.testAllProps = testPropsAll);
  var prefixed = (ModernizrProto.prefixed = function(e, n, t) {
    return 0 === e.indexOf("@")
      ? atRule(e)
      : (-1 != e.indexOf("-") && (e = cssToDOM(e)),
        n ? testPropsAll(e, n, t) : testPropsAll(e, "pfx"));
  });
  Modernizr.addTest("objectfit", !!prefixed("objectFit"), {
    aliases: ["object-fit"]
  }),
    testRunner(),
    setClasses(classes),
    delete ModernizrProto.addTest,
    delete ModernizrProto.addAsyncTest;
  for (var i = 0; i < Modernizr._q.length; i++) Modernizr._q[i]();
  window.Modernizr = Modernizr;
})(window, document);
